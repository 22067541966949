<template>
  <section class="locationMap__filter-list" v-bind:class="{
    'is-loading': loading
    }">
    <div class="locationMap__category-filter">
    <loader v-if="loading"/>
    <form method="post" action="#" v-on:submit.prevent="onSubmit">
      <fieldset class="locationMap-filter__group"
                v-for="fieldModel in filterModel"
                :key="fieldModel.targetProperty">
        <legend class="locationMap-filter__group-title">{{ fieldModel.title }}</legend>
        <div class="locationMap-filter__input"
             v-if="fieldModel.$type === 'Checkbox'">
          <label class="locationMap-filter__option"
                v-for="fieldValueOption in fieldModel.values"
                  :key="fieldValueOption.title">
              <input type="checkbox"
                    :value="fieldValueOption.$value"
                    v-on:change="commitChangeToStore"
                    v-model="fieldModel.selected">
              <span class="locationMap-filter__label">{{ fieldValueOption.title }}</span>
          </label>
        </div>
        <div class="locationMap-filter__input"
             v-if="fieldModel.$type === 'Text'">
              <input type="text"
                    v-on:input="commitChangeToStore"
                    v-model="fieldModel.$value">
        </div>
        <div class="locationMap-filter__input"
             v-if="fieldModel.$type === 'Select'">
              <select v-on:change="commitChangeToStore"
                      v-model="fieldModel.$value">
                      <option v-for="fieldValueOption in fieldModel.values"
                              :value="fieldValueOption.$value"
                              :key="fieldValueOption.title">
                              {{ fieldValueOption.title }}
                      </option>

              </select>
        </div>
      </fieldset>
    </form>
  </div>
  </section>
</template>

<style lang="scss">
@import "locationFilter.scss";
</style>

<script>
import { mapGetters } from 'vuex'
import loader from '@/components/loader/loader'

export default {
  components: { loader },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    toggleFilterView () {
      this.$store.commit('toggleFilterVisible')
    },
    commitChangeToStore (event) {
      this.loading = true
      this.$store.commit('setSelectedFilters', this.filterModel)
    }
  },
  updated () {
    this.loading = false
  },
  computed: {
    ...mapGetters([
      'filterModel'
    ])
  }
}
</script>
