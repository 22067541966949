

























import locationFilter from '@/components/locationFilter/locationFilter.vue'
import { mapGetters } from 'vuex'
import Vue from 'vue'

export default Vue.extend({
  data () {
    return {
      filterVisible: false
    }
  },
  components: {
    locationFilter
  },
  methods: {
    toggleFilterView () {
      this.$store.commit('toggleFilterVisible')
    },
    showDetail (item: any) {
      this.$store.commit('detailVisible', true)
      this.$store.commit('currentDetailItem', item)
    }
  },
  computed: {
    ...mapGetters([
      'visibleItems'
    ]),
    hasFilter () {
      return this.$store.getters.filterModel !== null && this.$store.getters.filterModel.length !== 0
    }
  }
})
