



















import locationMap from '@/components/locationMap/locationMap.vue' // @ is an alias to /src
import listView from '@/components/listView/listView.vue'
import detailView from '@/components/detailView/detailView.vue'

import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'wwListMap',
  components: {
    locationMap,
    listView,
    detailView
  },
  computed: {
    ...mapGetters([
      'filterVisible',
      'detailVisible'
    ])
  }
})
