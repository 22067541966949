import Vue from 'vue'
import App from './App.vue'
import store from './store'

import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

Vue.component('l-map', LMap)
Vue.component('l-tile-layer', LTileLayer)
Vue.component('l-marker', LMarker)

Vue.config.productionTip = false
const elementId = 'wwt3ListMap'

new Vue({
  store,
  render: h => h(App),
  beforeMount: function () {
    const rootEl = document.getElementById(elementId)
    if (rootEl) {
      const apiUrl = rootEl.getAttribute('api-url')
      this.$store.dispatch('initializeFromAPI', apiUrl)
      const assetsBase = rootEl.getAttribute('assets-base')
      this.$store.dispatch('setAssetsUrl', assetsBase)
      const boundsFromRootEl = rootEl.getAttribute('initial-bounds')
      // format x1+y1~x2+y2"
      if (boundsFromRootEl) {
        const initialBounds = [{ lat: '0', lng: '0' }, { lat: '1', lng: '1' }]
        const coordsString = decodeURIComponent(boundsFromRootEl.toString())
        const coords = coordsString.split('~')
        if (coords.length === 2) {
          const firstCorner = coords[0].split('+')
          const secondCorner = coords[1].split('+')
          initialBounds[0].lat = firstCorner[0]
          initialBounds[0].lng = firstCorner[1]
          initialBounds[1].lat = secondCorner[0]
          initialBounds[1].lng = secondCorner[1]
        }
        this.$store.dispatch('setMapBounds', initialBounds)
      }
    }
  }
}).$mount('#' + elementId)
