<template>
  <div class="loader"></div>
</template>

<script>
export default {
  name: 'loader'
}
</script>

<style lang="scss">
.loader,
.loader::before,
.loader::after {
  animation: load7 1.8s infinite ease-in-out;
  animation-fill-mode: both;
  border-radius: 50%;
  height: 2.5em;
  width: 2.5em;
}

.loader {
  animation-delay: -0.16s;
  color: var(--loader-color, grey);
  font-size: 10px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  text-indent: -9999em;
  transform: translateZ(0) translateY(-100%);
  z-index: 9999;
}

.loader::before,
.loader::after {
  content: '';
  position: absolute;
  top: 0;
}

.loader::before {
  animation-delay: -0.32s;
  left: -3.5em;
}

.loader::after {
  left: 3.5em;
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

</style>
