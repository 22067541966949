// import data from '../assets/locations10.json'
// const LATENCY = 0
import axios from 'axios'

export default {
  getLocations: function (url: string) {
    return axios.get(url)
      .then(response => {
        return response.data
      })
  },
  getDetail: function (url: string) {
    return axios.get(url)
      .then(response => {
        return response.data
      })
  }
}
