<template>
  <div>
    <loader v-if="loading"/>
    <l-map
      ref="map"
      :crs="layer.crs"
      :zoom="zoom"
      :center="center"
      :bounds="bounds"
      style="height: var(--map-height); width: 100%;"
    >
      <l-tile-layer
        :url="layer.url"
        :subdomains="layer.subdomains"
        :attribution="layer.attribution"
      />
      <v-marker-cluster>
        <l-marker
          v-for="marker in visibleItems"
          :key="marker.uid"
          :visible="marker.hasValidCoords"
          :draggable="false"
          :icon="makeIcon (marker.icon)"
          :lat-lng.sync="marker.position"
          v-on:click="showDetail (marker)"
        >
          <l-popup :content="marker.views.bubble" />
        </l-marker>
      </v-marker-cluster>
    </l-map>
  </div>
</template>

<script>
import { latLng, latLngBounds, Icon } from 'leaflet'
import { LMap, LTileLayer, LPopup } from 'vue2-leaflet'
import loader from '@/components/loader/loader'
import { mapGetters } from 'vuex'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'

// workaround for missing marker icons
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

export default {
  components: {
    loader,
    LMap,
    LTileLayer,
    LPopup,
    'v-marker-cluster': Vue2LeafletMarkerCluster
  },
  data () {
    return {
      zoom: 4,
      center: latLng(51, 13),
      loading: true,
      layer:
        {
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        }
    }
  },
  updated () {
    this.loading = false
  },
  methods: {
    showDetail (item) {
      this.$store.commit('detailVisible', true)
      this.$store.commit('currentDetailItem', item)
      this.$store.dispatch('setMapBounds', item.position)
      this.$refs.map.mapObject.invalidateSize()
    },
    makeIcon (icon) {
      return new Icon({
        iconUrl: this.$store.getters.assetsUrl + icon.file,
        iconSize: icon.size,
        iconAnchor: icon.anchor
      })
    },
    autoZoom () {
      const bounds = latLngBounds(this.visibleItems.filter(o => o.hasValidCoords).map(o => o.position))
      this.$store.dispatch('setMapBounds', bounds)
      this.$refs.map.mapObject.invalidateSize({
        animate: true,
        pan: true
      })
    }
  },
  watch: {
    '$store.getters.visibleItems.length' (value, oldValue) {
      setTimeout(this.autoZoom, 200)
    },
    '$store.state.filterVisible' (value, oldValue) {
      setTimeout(this.autoZoom, 300)
    }
  },
  computed: {
    ...mapGetters([
      'visibleItems',
      'assetsUrl'
    ]),
    bounds () {
      return latLngBounds(this.$store.getters.mapBounds)
    }
  }
}
</script>

<style lang="scss">
@import "locationMap.scss";
</style>
