



















import { mapGetters } from 'vuex'
import Vue from 'vue'
import loader from '@/components/loader/loader'

export default Vue.extend({
  components: { loader },
  data () {
    return {
      detailMarkup: '',
      loading: true
    }
  },
  methods: {
    closeDetail (item: any) {
      this.$store.commit('detailVisible', false)
    }
  },
  watch: {
    currentDetailItem: function (val, oldVal) {
      if (!val.detailMarkup) {
        this.loading = true
        this.$store.dispatch('loadDetailForItem', val)
          .then((item: any) => {
            this.detailMarkup = item.detailMarkup
            this.loading = false
          })
      } else {
        this.loading = false
        this.detailMarkup = val.detailMarkup
      }
    }
  },
  computed: {
    ...mapGetters([
      'currentDetailItem'
    ])
  }
})
